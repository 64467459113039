import { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom";

import axios from "axios";
import moment from "moment";

import LogChart2 from "./LogChart2";
import LogChart3 from "./LogChart3";
import LogTimeline from "./LogTimeline";

function PopupResult({visible, onClose, data}) {
    const [graphType,setGraphType]=useState(0);

    //useEffect(()=>{console.log('PopupResult data changed',data?.length)},[data]);

    if(!data)
        return (<div></div>)

    const popupStyle= {
        position:"fixed",
        top:120,
        left:0,
        right:0,
        bottom:0,
        display: visible ? "block" : "none",
        padding: "25px",
        backgroundColor: "rgba(30,30,30,0.5)",
        boxShadow: "5px 10px 10px 1px rgba(0,0,0,.5)",
        overflowY:"auto"
    }

    const articleStyle = {
        maxHeight:"500px",
        boxShadow: "5px 10px 10px 1px rgba(0,0,0,.5)",
        overflow:"auto"
    }


    return (
      <div style={popupStyle}>
        <article sytle={articleStyle}>
          <header style={{position:'sticky',top:0}}>
            <p><strong>로그 분석</strong></p>
            <button className={graphType != 1 && "outline"} onClick={()=>{setGraphType(1)}}>시간 차트</button> 
            <button className={graphType != 2 && "outline"} onClick={()=>{setGraphType(2)}}>수술 분석</button>
            <button className={graphType != 3 && "outline"} onClick={()=>{setGraphType(3)}}>장치 사용기록 분석</button>
          </header>
          <main>
            {/* <div style={{height:"200px", padding:"30px", overflowY:"auto", overflowH:"hidden"}}>{JSON.stringify(data)}</div> */}
            <div>
                {graphType == 1 && (<LogChart2 logData={data} />)}
                {graphType == 2 && (<LogTimeline logData={data} />)}
                {graphType == 3 && (<LogChart3 logData={data} />)}
            </div>
          </main>
        </article>
      </div>
    )
}

// 비동기 logToJSON 함수
async function logToJSONAsync(logContent, timeRange, onProgress = (cur, total) => {}) {
    const lines = logContent.split("\r").filter((line) => line.trim() !== "");
    const headers = lines[0].split("\t")[5].split(",");
    const total = lines.length - 1;
    const parsedData = [];
  
    for (let i = 1; i < lines.length; i++) {
        const line = lines[i];
        const parts = line.split("\t");
        if (parts.length < 6) continue;

        if (i % 1000 === 0) {
            onProgress(i, total);
            await new Promise((resolve) => setTimeout(resolve, 0)); // 비동기 업데이트
        }

        const timestamp = moment(`${parts[1]} ${parts[2]}`, "YYYY.MM.DD HH:mm:ss.SSS");
        const entry = {
            id: parts[0],
            datetime: timestamp.toISOString(),
            momenttime: timestamp,
            controller: parts[3],
            type: parts[4],
        };

        const values = parts[5].split(",");
        headers.forEach((header, index) => {
            entry[header] = isNaN(values[index]) ? values[index] : parseFloat(values[index]) || 0;
        });

        parsedData.push(entry);
    }

    onProgress(total, total);

    // 타임레인지 필터링
    if (timeRange) {
        return parsedData.filter((entry) => entry.momenttime.isBetween(timeRange[0], timeRange[1], undefined, "[]"));
    }
    return parsedData;
}

export default function AnalysisS3(){

    const [sparams] = useSearchParams();
    const fkey = sparams.get('fkey');
    const fname = sparams.get('fname');
    const stime = sparams.get('stime');
    const etime = sparams.get('etime');
    const hasRange = stime != null && etime != null;
    const trange = hasRange && ([moment(parseInt(stime)),moment(parseInt(etime))]) || null ;


    const [progress, setProgress] = useState();
    const [downloadedFile, setDownloadedFile] = useState(null);
    const [logContent, setLogContent] = useState(null);
    //const {fkey, fname} = useParams();
    
    const a = ()=>{
        
        let downloadCompleted = false;
        const config = {
            responseType: 'blob' ,
            headers: { 
                'access-token': localStorage.getItem('access-token')
            },
            onDownloadProgress: (progressEvent) =>{
                const totalLength = progressEvent.lengthComputable 
                        ? progressEvent.total
                        : parseInt(progressEvent.target.getResponseHeader('content-length'), 10);
                    if (totalLength && !downloadCompleted) {
                        let percent = Math.round((progressEvent.loaded * 100) / totalLength)
                        setProgress(`Download: ${percent} %`);
                    }
            }
        }

        axios.post(`/api/reqkey?key=${encodeURIComponent(fkey)}&filename=${encodeURIComponent(fname)}`, {}, config ) // 파일을 Blob으로 받음
        .then((response) => {
            //console.log('download completed: ', filename);
            downloadCompleted = true;
            setProgress("Download: 100 %");

            const file = new Blob([response.data]);
            setDownloadedFile(file); // 다운로드된 파일을 상위 컴포넌트의 상태로 전달

            const reader = new FileReader();
                reader.onload = async (e) => {

                    const parseLogProgress = (cur,total) => {setProgress(`Parsing: ${Math.round(cur*1000/total)/10} %`)}
                    const data = e.target.result;

                    //const stime = moment();
                    const jsonData = await logToJSONAsync(data, trange,parseLogProgress);
                    //const etime = moment();
                    //console.log(`Execution time (logToJSON): ${moment.duration(etime.diff(stime)).asMilliseconds()} ms`);

                    setLogContent(jsonData);
                };
                reader.readAsText(file);

        })
        .catch((error) => {
          console.error('File download error:', error);
        });
    }

    useEffect(()=>{
        a();
    },[]);

    return (
    <div>
        {progress}
        <PopupResult visible={logContent !== null} data={logContent} onClose={()=>{setLogContent(null)}}/>
    </div>
    )
}