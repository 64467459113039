import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';


const displayStringFromMM = (val) => {
    if (isNaN(val)) return ""; // 숫자가 아니면 빈 문자열 반환
    const num = parseFloat(val).toFixed(0); // 소수점 3자리 고정
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // 3자리마다 콤마 추가      
}

const columnMap = {
    'SMT_MBrakeFLActualPosition':   {text: '[모터]Master Brake FL', func: MotorDistanceStatistics},
    'SMT_MBrakeFRActualPosition':   {text: '[모터]Master Brake FR', func: MotorDistanceStatistics},
    'SMT_MBrakeBLActualPosition':   {text: '[모터]Master Brake BL', func: MotorDistanceStatistics},
    'SMT_MBrakeBRActualPosition':   {text: '[모터]Master Brake BR', func: MotorDistanceStatistics},
    'SMT_SBrakeFLActualPosition':   {text: '[모터]Slave Brake FL', func: MotorDistanceStatistics},
    'SMT_SBrakeFRActualPosition':   {text: '[모터]Slave Brake FR', func: MotorDistanceStatistics},
    'SMT_SBrakeBLActualPosition':   {text: '[모터]Slave Brake BL', func: MotorDistanceStatistics},
    'SMT_SBrakeBRActualPosition':   {text: '[모터]Slave Brake BR', func: MotorDistanceStatistics},
    'SMT_MElevLActualPosition':     {text: '[모터]Master Elevation L', func: MotorDistanceStatistics},
    'SMT_MElevRActualPosition':     {text: '[모터]Master Elevation R', func: MotorDistanceStatistics},
    'SMT_GimbalRootActualPosition': {text: '[모터]Master Gimbal Root', func: MotorDistanceStatistics},
    'SMT_FootPlateActualPosition':  {text: '[모터]Master Foot Plate', func: MotorDistanceStatistics},
    'SMT_ArmrestLActualPosition':   {text: '[모터]Master Amrest L', func: MotorDistanceStatistics},
    'SMT_ArmrestRActualPosition':   {text: '[모터]Master Amrest R', func: MotorDistanceStatistics},
    'SMT_BaseXActualPosition':      {text: '[모터]Slave Base X', func: MotorDistanceStatistics},
    'SMT_BaseYActualPosition':      {text: '[모터]Slave Base Y', func: MotorDistanceStatistics},
    'SMT_YawActualPosition':        {text: '★[모터]Slave Yaw', func: MotorDistanceStatistics},
    'SMT_PitchActualPosition':      {text: '★[모터]Slave Pitch', func: MotorDistanceStatistics},
    'SMT_SElevActualPosition':      {text: '★[모터]Slave Elevation', func: MotorDistanceStatistics},
    'SMT_ShthTranActualPosition':   {text: '★★[모터]Slave Sheath Translation', func: MotorDistanceStatistics},
    'SMT_TranActualPosition':       {text: '★★[모터]Slave Translation', func: MotorDistanceStatistics},
    'SMT_RollActualPosition':       {text: '★★[모터]Slave Roll', func: MotorRotationStatistics},
    'SMT_BendActualPosition':       {text: '★★[모터]Slave Bending', func: MotorRotationStatistics},
    'SMT_ToolTrActualPosition':     {text: '★★[모터]Slave Tool Tr', func: MotorDistanceStatistics},
    'SMT_ToolGrActualPosition':     {text: '★★[모터]Slave Tool Gr', func: MotorDistanceStatistics},
    'SDI_MEMG':     {text: '[스위치]Master Emegerncy', func: SwitchStatistics},
    'SDI_SEMG':     {text: '[스위치]Slave Emergency', func: SwitchStatistics},
    'SDI_JoySw':    {text: '[스위치]Joystick Switch', func: SwitchStatistics},
    'SDI_ClutchPd': {text: '[스위치]Clutch Pedal', func: SwitchStatistics},
    'SDI_ClutchSw': {text: '[스위치]Clutch Switch', func: SwitchStatistics},
    'SDI_FWD':      {text: '[스위치]Forward', func: SwitchStatistics},
    'SDI_BWD':      {text: '[스위치]Backward', func: SwitchStatistics},
    'SDI_Shift':    {text: '[스위치]Shift', func: SwitchStatistics},
    'SDI_Drape1':   {text: '[센서]Drape Equipment', func: SwitchStatistics},
    'SDI_ScopeEq':  {text: '[센서]Scope Equipment', func: SwitchStatistics},
    'SDI_BasketEq': {text: '[센서]Basket Equipment', func: SwitchStatistics},
    'SDI_LaserEq':  {text: '[스위치]Laser Equipment', func: SwitchStatistics},
    'SDI_DrapeTool':{text: '[스위치]Drape Tool Equipment', func: SwitchStatistics},
}

function MotorDistanceStatistics(data) {
    // console.log(data);
    const movementDistance = data.slice(1).reduce((sum, value, index) => sum + Math.abs(value - data[index]), 0);
    return [ movementDistance, 'mm 이동' ];
}

function MotorRotationStatistics(data) {
    // console.log(data);
    const movementDistance = data.slice(1).reduce((sum, value, index) => sum + Math.abs(value - data[index]), 0);
    return [ movementDistance, 'degree 회전' ];
}

function SwitchStatistics(data) {
    
    const switchPressCount = data.slice(1).map((value,index)=> data[index] > value ? 1 : 0)
                                    .reduce((sum,value) => sum + value, 0);

    return [ switchPressCount, '회 눌림'];
}

function StatisticsData({data,itemName}) {
    const [isLoading, setLoading] = useState(true);
    const [string, setString] = useState('');

    useEffect(()=>{
        const task = () => {
            return new Promise((resolve)=>{
                const cdata = extractChartData(data,itemName).map(v=>v.y);
                const ret = columnMap[itemName].func(cdata);
                resolve(ret);
            })
        };

        setTimeout(()=>{
            task().then((res)=>{
                setString(`${displayStringFromMM(res[0])} ${res[1]}`);
                setLoading(false);
            });
        },500)
    },[]);
    
    return (
        <span aria-busy={isLoading}> {string} </span>
    )
}
function ChartItem({orgData, columnNames, onShowChart}) {
    
    return (
    <div>
        <table>
        {columnNames.filter(v=>(columnMap[v] != null)).map((name,index)=>(
            <tr>
                <td>
                    {columnMap[name].text} 
                </td>
                <td style={{textAlign:'right'}}>
                    <StatisticsData data={orgData} itemName={name} />
                </td>
                <td>
                    <button onClick={()=>{onShowChart(name)}}>차트 보기</button>
                </td>
            </tr>

        ))}
        </table>
    </div>
    );
}


function PopupChart({visible, onClose, children}) {
    const popupStyle= {
        position:"fixed",
        top:0,
        left:0,
        right:0,
        bottom:0,
        display: visible ? "block" : "none",
        padding: "25px",
        backgroundColor: "rgba(30,30,30,0.5)",
        boxShadow: "5px 10px 10px 1px rgba(0,0,0,.5)",
        overflowY:"auto"
    }

    const articleStyle = {
        maxHeight:"500px",
        boxShadow: "5px 10px 10px 1px rgba(0,0,0,.5)",
        overflow:"auto"
    }


    return (
      <div style={popupStyle}>
        <article sytle={articleStyle}>
          <header>
            <button onClick={onClose}> Close </button>
          </header>
          <main>
            {children}
          </main>
        </article>
      </div>
    )
}

function optimizeData(data, key) {
    if (data.length == 0) return [];

    const optimizedData = [data[0]]; // Start with the first data point

    let last = 0;

    const isDataChanged = (old,cur) => {
        if(isNaN(old) || isNaN(cur))
            return old != cur;

        return Math.abs(cur-old) > Math.abs(cur * 0.003);
    };

    for (let i = 1; i < data.length; i++) {
        //if (data[i][key] !== data[i - 1][key]) {
        if ( isDataChanged(data[i][key],data[last][key])) {
            if(last != i-1)
                optimizedData.push(data[i-1]);
            optimizedData.push(data[i]); // Add only when the value changes
            last = i;
        }
    }

    if(last < data.length-1)
        optimizedData.push(data[data.length-1]);

    // if(opt == true)
    //     optimizedData.push(data[-1])

    return optimizedData;
}

const extractChartData = (data, item) => {
    const optimizedData = optimizeData(data, item);

    const ret = optimizedData.map(entry => ({x:entry.datetime, y:parseFloat(entry[item]) || 0}));
    // const times = optimizedData.map(entry => entry.datetime);
    // const values = optimizedData.map(entry => parseFloat(entry[item]) || 0);
    
    return ret;
}

export default function LogChart3({logData}){
    const data = logData;
    const [chartData, setChartData] = useState({});
    const [dispChart, showChart] = useState(false);

    const columnNames = data.length > 0 ? Object.keys(data[0]).filter(key => key !== 'datetime' && key !== 'id' && key !== 'controller' && key !== 'type') : [];

    const options = {
        scales: {
            x: {
                type: 'time',  // Set the x-axis type to 'time'
                time: {
                    unit: 'second',  // Adjust the time unit as needed (e.g., 'minute', 'hour', 'day')
                    tooltipFormat: 'yyyy-MM-dd HH:mm:ss.SSS',  // Format for tooltips
                },
                title: {
                    display: true,
                    text: 'Time'
                }
            },
        },

        plugins: {
            tooltip: {
                intersect: false,   // Tooltip will appear even when not directly over a point
                mode: 'index'       // Tooltip shows the data for the nearest x-axis point
            }, 
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',  // Enable panning on the x-axis
                },
                zoom: {
                    wheel: {
                        enabled: true,  // Enable zooming with the mouse wheel
                    },
                    pinch: {
                        enabled: true  // Enable zooming with pinch gestures
                    },
                    mode: 'x',  // Enable zooming on the x-axis
                }
            }
        }
    };
    
    const generateChartData = (itemName) => {
        if (itemName && data.length > 0) {
            // const optimizedData = optimizeData(data, selectedItem);

            const times = data.map(entry => entry.datetime);
            //const values = optimizedData.map(entry => parseFloat(entry[selectedItem]) || 0);

            let values = extractChartData(data,itemName);


            let datasets = [];

            datasets.push({
                label: itemName + `(${values.length}/${data.length})`,
                data: values,
                fill: false,
                borderColor: 'rgba(75,192,192,1)',
                tension: 0.1,
                pointRadius: 0,
                borderWidth: 1
            });



            setChartData({
                labels: times,
                datasets: datasets
            });

            showChart(true);

            // Scroll to the bottom of the page
            // setTimeout(() => {
            //     window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
            // }, 100);  // Delay to ensure chart is rendered
        }
    };

    return (
        <>
            <div>
                { data.length > 0 && 
                    (<ChartItem label="Item 1" 
                        orgData={data}
                        columnNames={columnNames} 
                        onShowChart={(itemName)=>{generateChartData(itemName)}}
                        />)
                }
            </div>
            <PopupChart visible={dispChart} onClose={()=>{showChart(false)}} >
                {chartData.labels && (<Line data={chartData} options={options} plugins={[zoomPlugin]} />)}
            </PopupChart>
        </>
    )
}